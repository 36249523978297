import React from 'react';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';
import {FilterType, IFilterSelectionValue} from '../../../../types/galleryTypes';
import {Tag, TagSize, TextButton} from 'wix-ui-tpa';
import {classes} from './AppliedFilters.st.css';
import {getActiveFilterOptions} from '../filterUtils';
import {AppliedFiltersTagSize} from '../../../../constants';
import {useStyles} from '@wix/tpa-settings/react';
import {useStylesParams} from '../../../../stylesParamsContext';

interface IAppliedFiltersProps extends IGalleryGlobalProps {
  shouldShowMobile?: boolean;
  filterModels?: boolean;
  filterProducts?: (filterId: number, value: IFilterSelectionValue) => void;
}

export enum DataHook {
  AppliedFilterTag = 'applied-filter-tag',
  ClearFilters = 'applied-filters-clear',
}

export const AppliedFilters: React.FunctionComponent<IAppliedFiltersProps> = withGlobals(
  ({globals}: IAppliedFiltersProps) => {
    const {filterModels, filterProducts, shouldShowMobile, clearFilters} = globals;
    const styles = useStyles();
    const stylesParams = useStylesParams();
    const tagSize = styles.get(stylesParams.gallery_appliedFiltersTagSize);

    const getActiveFilterOptionTagText = (activeOption) => {
      switch (activeOption.filterType) {
        case FilterType.COLLECTION:
        case FilterType.COLOR_OPTION:
        case FilterType.CUSTOM_COLLECTION:
        case FilterType.LIST_OPTION:
          return `${activeOption.value}`;
        case FilterType.PRICE:
          return `Price from ${activeOption.minValue} to ${activeOption.maxValue}`;
      }
    };

    const tagSizes = {
      [AppliedFiltersTagSize.SMALL]: TagSize.small,
      [AppliedFiltersTagSize.MEDIUM]: TagSize.medium,
      [AppliedFiltersTagSize.LARGE]: TagSize.large,
    };

    const appliedFilters = getActiveFilterOptions(filterModels, filterProducts);

    return (
      <>
        {appliedFilters.map((activeOption, index) => (
          <Tag
            key={`${activeOption.filterId}-${activeOption.value}`}
            size={shouldShowMobile ? TagSize.small : tagSizes[tagSize]}
            isRemovable
            onRemove={() => activeOption.removeFunc()}
            className={classes.tagRoot}
            data-hook={`${DataHook.AppliedFilterTag}-${index}`}>
            {getActiveFilterOptionTagText(activeOption)}
          </Tag>
        ))}
        {appliedFilters.length && (
          <TextButton
            onClick={() => clearFilters()}
            className={classes.textButtonRoot}
            data-hook={DataHook.ClearFilters}>
            Clear All
          </TextButton>
        )}
      </>
    );
  }
);
